<template>
  <div class="insurance">
    <div class="title">建设银行农业统一托管经营收入保险</div>
    <div class="content">
      <div class="line">
        <div class="label">保险范围</div>
        <div class="detail">
          （一）保险标的因遭受自然灾害、意外事故或病虫草鼠害发生绝产导致被保险人没有收入的；<br />
          （二）保险标的因遭受自然灾害、意外事故或病虫草鼠害发生损失导致被保险人收入下降的；<br />
          （三）因粮食作物市场价格下跌导致被保险人获得的实际收入减少的。
        </div>
      </div>

      <div class="line">
        <div class="label">保险金额</div>
        <div class="detail">
          （一）保险标的的每亩保险金额（即保险亩均收入）根据保险标的的每亩保险产量及保险价格以及保障水平确定；<br />
          （二）每亩保险产量、保险价格参照保险标的过去三至五年亩均产量、销售价格的算数平均值，具体由投保人与保险人协商确定；<br />
          （三）保障水平由投保人与保险人协商确定。
        </div>
      </div>
      <div class="line">
        <div class="label">赔偿金额</div>
        <div class="detail">
          发生保险事故时，保险人按以下公式计算赔偿金额：
          赔偿金额=（保险亩均收入-实际亩均收入）（元/亩）×保险面积（亩）
          实际亩均收入=实测亩均产量（公斤/亩）×实际平均销售价格（元/公斤）。
        </div>
      </div>
      <div class="line">
        <div class="label">保险期间</div>
        <div class="detail">
          本保险合同的保险责任期间自保险标的出苗或移栽成活后起，至保险标的销售结束时止，具体保险期间以保险单载明的起讫时间为准。
        </div>
      </div>
      <div class="line">
        <div class="label">保险费率</div>
        <div class="detail">更优惠</div>
      </div>
      <a-button type="primary" @click="showModel"> 保险申请 </a-button>
    </div>
    <a-modal v-model="visible" title="保险申请" :footer="null">
      <a-form-model ref="ngjFinanceInsureForm" :model="ngjFinanceInsure" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" >
        <a-form-model-item label="客户姓名" prop="customName">
          <a-input v-model="ngjFinanceInsure.customName" />
        </a-form-model-item>
        <a-form-model-item label="客户手机" prop="customPhone">
          <a-input v-model="ngjFinanceInsure.customPhone" />
        </a-form-model-item>
        <a-form-model-item label="客户地址" prop="location">
          <a-input v-model="ngjFinanceInsure.location" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="handleOk">
            提交
          </a-button>
          <a-button style="margin-left: 10px;" @click="handleCancel">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/trusteeships";
export default {
  data() {
    return {
      visible:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      ngjFinanceInsure: {
        customName: '',
        customPhone: '',
        location: '',
      },
       rules: {
        customName: [
          { required: true, message: 'Please input customName', trigger: 'blur' },
        ],
        customPhone: [
          { required: true, message: 'Please input customPhone', trigger: 'blur' },
        ],
        location: [
          { required: true, message: 'Please input location', trigger: 'blur' },
        ],
        
      },
    }
  },
  components: {

  },
  methods: {
    showModel(){
      this.visible = true;
      this.ngjFinanceInsure={};
    },
    handleOk() {
      this.$refs.ngjFinanceInsureForm.validate(valid => {
         if (valid) {
           Api.addInsure({ ...this.ngjFinanceInsure })
            .then((res) => {
            console.log(res.code);
            this.visible = false;
            })
            .catch(() => (this.loading = false));
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  color: #008840;
  font-size: 18px;
  text-indent: 1em;
  background: #f9f9f9;
  border-bottom: 1px solid #008840;
  margin-bottom: 15px;
  line-height: 59px;
  font-weight: bold;
}
.content {
  margin-bottom: 30px;
  .line {
    overflow: hidden;
    margin-bottom: 10px;
    .label {
      float: left;
      background: url("~@/assets/images/finance_insurance_label.png") no-repeat center
        center / 100%;
      font-size: 16px;
      color: white;
      padding: 7px 10px;
    }
    .detail {
      float: left;
      width: 80%;
      line-height: 2em;
      padding-top: 0.6em;
      margin-left: 20px;
      &.strong {
        color: #68cba5;
      }
    }
  }
}
</style>
